<template>
  <div class="sign">
    <div class="tc">
      <el-image
        :src="logo"
        class="image cursor"
        @click="$router.push('/')"
      ></el-image>
    </div>
    <div class="subtitle">—— {{ $t("innovationCooperation") }} ——</div>
    <div class="loginContent" :class="LOCALE == 'en' ? 'enTabs' : 'zhTabs'">
      <div class="otherLogin">
        <div class="otherTips">
          <div class="line"></div>
          <div class="txtTips">{{ $t("otherAccont") }}</div>
        </div>
        <div class="iconList tc">
          <img
            @click="gotoWxlogin"
            class="wxImg"
            :src="wxImg"
            :title="$t('wechatLogin')"
          />
          <img @click="gotoAiLa" :src="ailaImg" :title="$t('ailalogin')" />
          <img @click="gotoJinCheng" :src="jinImg" :title="$t('jctranLogin')" />
        </div>
      </div>
      <div class="emailSign" v-if="IS_EN">
        <sign
          @successChange="loginSuccess"
          :expires="expires"
          @getLoading="btnLoadingChange"
        >
          <el-button
            slot="signBtn"
            type="primary"
            class="loginBut"
            :loading="emailLoading"
            >{{ $t("sign") }}</el-button
          ></sign
        >
      </div>
      <el-tabs v-model="activeName" class="tabs" v-else>
        <el-tab-pane :label="$t('phoneLogin')" name="first">
          <div class="emailSign">
            <sign
              :signType="'phone'"
              @successChange="loginSuccess"
              :expires="expires"
              @getLoading="btnLoadingChange"
            >
              <el-button
                slot="signBtn"
                type="primary"
                class="loginBut"
                :loading="btnLoading"
                >{{ $t("sign") }}</el-button
              >
            </sign>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('emailLogin')" name="second">
          <div class="emailSign">
            <sign
              @successChange="loginSuccess"
              :expires="expires"
              @getLoading="btnLoadingChange"
            >
              <el-button
                slot="signBtn"
                type="primary"
                class="loginBut"
                :loading="emailLoading"
                >{{ $t("sign") }}</el-button
              ></sign
            >
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="clearfix">
        <div class="forgetPassword fl">
          <router-link to="/forgetPassword"
            >{{ $t("forgetPassword") }}？
          </router-link>
        </div>
        <div class="toReigster fr">
          <a @click="toRegister" class="registerText cursor mainColor">
            {{ $t("immedRegister") }}
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="otherLogin">
      <div class="otherTips">
        <div class="line"></div>
        <div class="txtTips">{{ $t("otherAccont") }}</div>
      </div>
      <div class="iconList tc">
        <img
          @click="gotoWxlogin"
          class="wxImg"
          :src="wxImg"
          :title="$t('wechatLogin')"
        />
        <img @click="gotoAiLa" :src="ailaImg" :title="$t('ailalogin')" />
        <img @click="gotoJinCheng" :src="jinImg" :title="$t('jctranLogin')" />
      </div>
    </div> -->
  </div>
</template>
<script>
import sign from "~/baseComponents/baseSign/sign";
import { getAilaDomainName } from "~/baseUtils";
export default {
  components: { sign },
  data() {
    return {
      activeName: "first",
      btnLoading: false,
      emailLoading: false,
      logo: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/logo1.png",
      jinImg: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/jinImg.png",
      ailaImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/logo/logo_b.png",
      wxImg: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/wx.png",
      expires: 7,
    };
  },
  mounted() {
    const url = getAilaDomainName(this.LOCALE);
    const urlPath = `${url}/users/sign/otherLogin`;
    this.$store.commit("SET_ISAPPLY_SHOW", true);
  },
  methods: {
    gotoWxlogin() {
      const redirect_uri = encodeURI(`${window.location.origin}/wxLogin`);
      window.location.href = `https://wx.ailaworld.com?appIdType=EXP&redirect_url=${redirect_uri}`;
    },
    gotoJinCheng() {
      const redirect_uri = encodeURI(`${window.location.origin}/wxLogin`);
      window.location.href = `https://expologin.ailaworld.com?remarks=${redirect_uri}`;
    },
    gotoAiLa() {
      const redirect_uri = encodeURI(`${window.location.origin}/wxLogin`);
      const url = getAilaDomainName(this.LOCALE);
      const urlPath = `${url}/users/sign/otherLogin`;
      window.location.href = `https://expologin.ailaworld.com?aila_path=${urlPath}&type=AiLa&remarks=${redirect_uri}`;
    },
    btnLoadingChange(val) {
      if (this.activeName == "first") {
        this.btnLoading = val;
      } else {
        this.emailLoading = val;
      }
    },
    async loginSuccess(val) {
      let params = {
        meeting_id: this.$store.state.meeting_id,
        user_id: this.USER_INFO.id,
        company_id: this.USER_INFO.company_id,
      };
      let result = await this.$store.dispatch(
        "baseStore/baseSign_getMeetingEnrollInfo",
        params
      );
      this.btnLoading = false;
      this.$router.push("/");
    },
    toRegister() {
      this.$router.push("/signUp");
    },
  },
};
</script>
<style lang="less" scoped>
.sign {
  width: 470px;
  padding: 0 40px;
  .image {
    width: 320px;
  }
  .subtitle {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    min-height: 30px;
    margin: 30px 0;
    line-height: 25px;
  }
  .loginContent {
    // margin-top: 30px;
    .loginBut {
      width: 390px;
      display: block;
      height: 40px;
      margin: 24px auto 12px;
      border: 0 none;
      font-size: 16px;
    }
  }
  .emailSign {
    margin-top: 10px;
  }
  .forgetPassword {
    a {
      color: #999999;
    }
  }
  .otherLogin {
    margin-top: 30px;
    padding-top: 5px;
  }
  .otherTips {
    color: #999;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      background: #ececec;
    }
    .txtTips {
      background: #ffffff;
      padding: 0 20px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: -10px;
    }
  }
  .iconList {
    margin-top: 20px;
    .wxImg {
      width: 35px;
      height: auto;
    }
    img {
      height: 20px;
      margin: 0 8px;
      cursor: pointer;
    }
  }
}
</style>